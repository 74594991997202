import { render } from "./SaveDialog.vue?vue&type=template&id=591a447c"
import script from "./SaveDialog.vue?vue&type=script&lang=ts"
export * from "./SaveDialog.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QDialog,QCard,QCardSection,QCardActions});qInstall(script, 'directives', {ClosePopup});
