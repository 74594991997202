import { defineComponent } from '@vue/runtime-core';
export default defineComponent({
    name: 'Delete Dialog',
    props: {
        title: String,
        msg1: String,
        msg2: String,
        deleteFunction: Function,
        selected: Array,
        multi: Boolean
    },
    data() {
        return {
            show: false
        };
    },
    computed: {
        disableButton() {
            if (this.multi && this.selectedItems.length < 1) {
                return true;
            }
            else {
                return false;
            }
        },
        selectedItems() {
            if (this.selected) {
                return this.selected;
            }
            else {
                return [];
            }
        },
        buttonLabel() {
            if (this.selectedItems.length > 1) {
                return 'DELETE RECORDS';
            }
            else {
                return 'DELETE RECORD';
            }
        }
    },
    methods: {
        startDelete() {
            if (this.multi && this.deleteFunction !== undefined) {
                this.deleteFunction();
                this.show = false;
            }
            else if (this.deleteFunction !== undefined) {
                this.deleteFunction();
            }
        }
    }
});
