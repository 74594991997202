import { defineComponent } from '@vue/runtime-core';
export default defineComponent({
    name: 'Save Dialog',
    props: {
        title: String,
        msg1: String,
        msg2: String,
        saveFunction: Function,
        selected: Array,
        disabled: Boolean,
        loading: Boolean
    },
    data() {
        return {
            show: false
        };
    },
    computed: {
        disableButton() {
            return this.disabled;
        },
        selectedItems() {
            if (this.selected) {
                return this.selected;
            }
            else {
                return [];
            }
        },
        buttonLabel() {
            return 'SAVE';
        }
    },
    methods: {
        startSave() {
            if (this.saveFunction !== undefined) {
                this.saveFunction();
                this.show = false;
            }
        }
    }
});
