import { createRouter, createWebHashHistory } from 'vue-router';
import Login from '@/views/Login.vue';
import Supplier from '@/views/SuppliersLayout.vue';
import { getCurrentUser } from '@/api/dataentry-api';
const routes = [
    {
        path: '/',
        redirect: { name: 'login' },
        component: () => import('@/layouts/MainLayout.vue'),
        children: [
            { path: 'login', component: Login, name: 'login', meta: { requiresAuth: false } },
            {
                name: 'Home',
                path: 'home',
                component: () => import('@/views/Home.vue'),
                meta: { requiresAuth: true }
            },
            {
                name: 'Suppliers',
                path: '/suppliers',
                component: Supplier,
                meta: { requiresAuth: true }
            },
            {
                name: 'Supplier',
                path: 'suppliers/:uuid',
                component: () => import('@/views/Supplier.vue'),
                meta: { requiresAuth: true }
            },
            {
                name: 'Cat. 1 - Purchased Goods & Services Records',
                path: '/category-one',
                component: () => import('@/views/CategoryOne/CategoryOneLayout.vue'),
                meta: { requiresAuth: true }
            },
            {
                name: 'Cat. 1 - Purchased Goods & Services',
                path: 'category-one/:uuid',
                component: () => import('@/views/CategoryOne/CategoryOne.vue'),
                meta: { requiresAuth: true }
            },
            {
                name: 'Cat. 4 - Upstream Transportation & Distribution Records',
                path: '/category-four',
                component: () => import('@/views/CategoryFour/CategoryFourLayout.vue'),
                meta: { requiresAuth: true }
            },
            {
                name: 'Cat. 4 - Upstream Transportation & Distribution',
                path: 'category-four/:uuid',
                component: () => import('@/views/CategoryFour/CategoryFour.vue'),
                meta: { requiresAuth: true }
            },
            {
                name: 'Cat. 11 - Use of Sold Products Records',
                path: '/category-eleven',
                component: () => import('@/views/CategoryEleven/CategoryElevenLayout.vue'),
                meta: { requiresAuth: true }
            },
            {
                name: 'Cat. 11 - Use of Sold Products',
                path: 'category-eleven/:uuid',
                component: () => import('@/views/CategoryEleven/CategoryEleven.vue'),
                meta: { requiresAuth: true }
            },
            {
                name: 'Cat. 12 - EOL Treatment of Sold Products Records',
                path: '/category-twelve',
                component: () => import('@/views/CategoryTwelve/CategoryTwelveLayout.vue'),
                meta: { requiresAuth: true }
            },
            {
                name: 'Cat. 12 - EOL Treatment of Sold Products',
                path: 'category-twelve/:uuid',
                component: () => import('@/views/CategoryTwelve/CategoryTwelve.vue'),
                meta: { requiresAuth: true }
            },
            {
                name: 'NotFound',
                path: '/:pathMatch(.*)*',
                component: () => import('@/views/404.vue')
            }
        ]
    }
];
const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
});
router.beforeEach(async (to) => {
    if (to.meta.requiresAuth) {
        try {
            await getCurrentUser();
            // OK
        }
        catch (error) {
            // User not authenticated - redirect to login
            return '/login';
        }
    }
});
export default router;
