import { activeConfig } from '@/config';
import * as generatedClient from './dataentry-api-generated';
generatedClient.defaults.baseUrl = activeConfig().apiBaseUrl;
generatedClient.defaults.fetch = async (input, init) => {
    generatedClient.defaults.baseUrl = activeConfig().apiBaseUrl; // TODO - The config is now always loaded yet, use vue_start method
    const response = await fetch(input, {
        credentials: 'include',
        ...init
    });
    if (response.ok) {
        return response;
    }
    else {
        throw await response.json();
    }
};
export * from './dataentry-api-generated';
