import { createStore } from 'vuex';
import { getCurrentUser, postSyncDataToYellowfin } from '@/api/dataentry-api';
import Notify from 'quasar/src/plugins/Notify.js';
export default createStore({
    state: {
        authenticated: false,
        user: {}
    },
    mutations: {
        setAuth(state, auth) {
            state.authenticated = auth;
        },
        setUser(state, user) {
            state.user = user;
        }
    },
    actions: {
        async checkAuth(context) {
            try {
                const user = await getCurrentUser();
                context.commit('setAuth', true);
                context.commit('setUser', user);
                return user;
            }
            catch (error) {
                context.commit('setAuth', false);
                throw error;
            }
        },
        async getUser(context) {
            try {
                const user = await getCurrentUser();
                context.commit('setAuth', user);
            }
            catch (error) {
                console.log('store : error : getUser', error);
            }
        },
        async startDataWarehouseTransform(context) {
            try {
                const result = await postSyncDataToYellowfin();
                context.dispatch('addMessage', 'Successfully started process to sync Data Warehouse');
                console.log('Transform process link: ', result.consoleLink);
            }
            catch (error) {
                console.log('store : error : syncDataWarehouseTransform', error);
                context.dispatch('addError', { message: 'Unexpected error syncing the Data Warehouse' });
                context.dispatch('addError', error);
            }
        },
        // Log methods for use within the store context only
        addError(context, error) {
            Notify.create({
                type: 'negative',
                timeout: 5000,
                message: error.message
            });
        },
        addMessage(context, message) {
            Notify.create({
                type: 'positive',
                message: message
            });
        }
    },
    modules: {}
});
Notify.setDefaults({
    position: 'top',
    timeout: 2500,
    textColor: 'white',
    actions: [{ icon: 'close', color: 'white' }]
});
