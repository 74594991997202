/**
 * Bunnings data entry API
 * 0.0.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
export const defaults = {
    baseUrl: "/"
};
export const servers = {};
export const _ = {
    async fetch(url, req) {
        const { baseUrl, headers, fetch: customFetch, ...init } = {
            ...defaults,
            ...req
        };
        const href = _.joinUrl(baseUrl, url);
        const res = await (customFetch || fetch)(href, {
            ...init,
            headers: _.stripUndefined({ ...defaults.headers, ...headers })
        });
        if (!res.ok) {
            throw new HttpError(res.status, res.statusText, href);
        }
        return res.text();
    },
    async fetchJson(url, req = {}) {
        const res = await _.fetch(url, {
            ...req,
            headers: {
                ...req.headers,
                Accept: "application/json"
            }
        });
        return res && JSON.parse(res);
    },
    json({ body, headers, ...req }) {
        return {
            ...req,
            body: JSON.stringify(body),
            headers: {
                ...headers,
                "Content-Type": "application/json"
            }
        };
    },
    form({ body, headers, ...req }) {
        return {
            ...req,
            body: QS.form(body),
            headers: {
                ...headers,
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };
    },
    multipart({ body, ...req }) {
        const data = new FormData();
        Object.entries(body).forEach(([name, value]) => {
            data.append(name, value);
        });
        return {
            ...req,
            body: data
        };
    },
    /**
     * Deeply remove all properties with undefined values.
     */
    stripUndefined(obj) {
        return obj && JSON.parse(JSON.stringify(obj));
    },
    // Encode param names and values as URIComponent
    encodeReserved: [encodeURIComponent, encodeURIComponent],
    allowReserved: [encodeURIComponent, encodeURI],
    /**
     * Creates a tag-function to encode template strings with the given encoders.
     */
    encode(encoders, delimiter = ",") {
        const q = (v, i) => {
            const encoder = encoders[i % encoders.length];
            if (typeof v === "object") {
                if (Array.isArray(v)) {
                    return v.map(encoder).join(delimiter);
                }
                const flat = Object.entries(v).reduce((flat, entry) => [...flat, ...entry], []);
                return flat.map(encoder).join(delimiter);
            }
            return encoder(String(v));
        };
        return (strings, ...values) => {
            return strings.reduce((prev, s, i) => {
                return `${prev}${s}${q(values[i] || "", i)}`;
            }, "");
        };
    },
    /**
     * Separate array values by the given delimiter.
     */
    delimited(delimiter = ",") {
        return (params, encoders = _.encodeReserved) => Object.entries(params)
            .filter(([, value]) => value !== undefined)
            .map(([name, value]) => _.encode(encoders, delimiter) `${name}=${value}`)
            .join("&");
    },
    joinUrl(...parts) {
        return parts
            .filter(Boolean)
            .join("/")
            .replace(/([^:]\/)\/+/, "$1");
    }
};
/**
 * Functions to serialize query parameters in different styles.
 */
export const QS = {
    /**
     * Join params using an ampersand and prepends a questionmark if not empty.
     */
    query(...params) {
        const s = params.join("&");
        return s && `?${s}`;
    },
    /**
     * Serializes nested objects according to the `deepObject` style specified in
     * https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.0.md#style-values
     */
    deep(params, [k, v] = _.encodeReserved) {
        const qk = _.encode([s => s, k]);
        const qv = _.encode([s => s, v]);
        // don't add index to arrays
        // https://github.com/expressjs/body-parser/issues/289
        const visit = (obj, prefix = "") => Object.entries(obj)
            .filter(([, v]) => v !== undefined)
            .map(([prop, v]) => {
            const index = Array.isArray(obj) ? "" : prop;
            const key = prefix ? qk `${prefix}[${index}]` : prop;
            if (typeof v === "object") {
                return visit(v, key);
            }
            return qv `${key}=${v}`;
        })
            .join("&");
        return visit(params);
    },
    /**
     * Property values of type array or object generate separate parameters
     * for each value of the array, or key-value-pair of the map.
     * For other types of properties this property has no effect.
     * See https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.0.md#encoding-object
     */
    explode(params, encoders = _.encodeReserved) {
        const q = _.encode(encoders);
        return Object.entries(params)
            .filter(([, value]) => value !== undefined)
            .map(([name, value]) => {
            if (Array.isArray(value)) {
                return value.map(v => q `${name}=${v}`).join("&");
            }
            if (typeof value === "object") {
                return QS.explode(value, encoders);
            }
            return q `${name}=${value}`;
        })
            .join("&");
    },
    form: _.delimited(),
    pipe: _.delimited("|"),
    space: _.delimited("%20")
};
export class HttpError extends Error {
    constructor(status, message, url) {
        super(`${url} - ${message} (${status})`);
        this.status = status;
    }
}
export async function getRoot(opts) {
    return await _.fetchJson("/", {
        ...opts
    });
}
/**
 * Get all Category Eleven records
 */
export async function getCategoryElevenRecords({ supplier, deleted, exclusiveStartKey, pageSize, pageNumber, orderByField, orderByAscending } = {}, opts) {
    return await _.fetchJson(`/category-eleven${QS.query(QS.form({
        supplier,
        deleted,
        exclusiveStartKey,
        pageSize,
        pageNumber,
        orderByField,
        orderByAscending
    }))}`, {
        ...opts
    });
}
/**
 * Create a new Category Eleven record
 */
export async function postCategoryEleven(upsertCategoryElevenRequest, opts) {
    return await _.fetchJson("/category-eleven", _.json({
        ...opts,
        method: "POST",
        body: upsertCategoryElevenRequest
    }));
}
/**
 * Get single Category Eleven record
 */
export async function getCategoryElevenRecord(categoryElevenUuid, opts) {
    return await _.fetchJson(`/category-eleven/${categoryElevenUuid}`, {
        ...opts
    });
}
/**
 * Update an existing Category Eleven record
 */
export async function putCategoryElevenRecord(categoryElevenUuid, upsertCategoryElevenRequest, opts) {
    return await _.fetchJson(`/category-eleven/${categoryElevenUuid}`, _.json({
        ...opts,
        method: "PUT",
        body: upsertCategoryElevenRequest
    }));
}
/**
 * Delete an existing Category Eleven record
 */
export async function deleteCategoryElevenRecord(categoryElevenUuid, opts) {
    return await _.fetchJson(`/category-eleven/${categoryElevenUuid}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Get all Category Four records
 */
export async function getCategoryFourRecords({ supplier, deleted, exclusiveStartKey, pageSize, pageNumber, orderByField, orderByAscending } = {}, opts) {
    return await _.fetchJson(`/category-four${QS.query(QS.form({
        supplier,
        deleted,
        exclusiveStartKey,
        pageSize,
        pageNumber,
        orderByField,
        orderByAscending
    }))}`, {
        ...opts
    });
}
/**
 * Create a new Category Four record
 */
export async function postCategoryFour(upsertCategoryFourRequest, opts) {
    return await _.fetchJson("/category-four", _.json({
        ...opts,
        method: "POST",
        body: upsertCategoryFourRequest
    }));
}
/**
 * Get single Category Four record
 */
export async function getCategoryFourRecord(categoryFourUuid, opts) {
    return await _.fetchJson(`/category-four/${categoryFourUuid}`, {
        ...opts
    });
}
/**
 * Update an existing Category Four record
 */
export async function putCategoryFourRecord(categoryFourUuid, upsertCategoryFourRequest, opts) {
    return await _.fetchJson(`/category-four/${categoryFourUuid}`, _.json({
        ...opts,
        method: "PUT",
        body: upsertCategoryFourRequest
    }));
}
/**
 * Delete an existing Category Four record
 */
export async function deleteCategoryFourRecord(categoryFourUuid, opts) {
    return await _.fetchJson(`/category-four/${categoryFourUuid}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Get all Category One records
 */
export async function getCategoryOneRecords({ supplier, deleted, exclusiveStartKey, pageSize, pageNumber, orderByField, orderByAscending } = {}, opts) {
    return await _.fetchJson(`/category-one${QS.query(QS.form({
        supplier,
        deleted,
        exclusiveStartKey,
        pageSize,
        pageNumber,
        orderByField,
        orderByAscending
    }))}`, {
        ...opts
    });
}
/**
 * Create a new Category One record
 */
export async function postCategoryOne(upsertCategoryOneRequest, opts) {
    return await _.fetchJson("/category-one", _.json({
        ...opts,
        method: "POST",
        body: upsertCategoryOneRequest
    }));
}
/**
 * Get single Category One record
 */
export async function getCategoryOneRecord(categoryOneUuid, opts) {
    return await _.fetchJson(`/category-one/${categoryOneUuid}`, {
        ...opts
    });
}
/**
 * Update an existing Category One record
 */
export async function putCategoryOneRecord(categoryOneUuid, upsertCategoryOneRequest, opts) {
    return await _.fetchJson(`/category-one/${categoryOneUuid}`, _.json({
        ...opts,
        method: "PUT",
        body: upsertCategoryOneRequest
    }));
}
/**
 * Delete an existing Category One record
 */
export async function deleteCategoryOneRecord(categoryOneUuid, opts) {
    return await _.fetchJson(`/category-one/${categoryOneUuid}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Get all Category Twelve records
 */
export async function getCategoryTwelveRecords({ supplier, deleted, exclusiveStartKey, pageSize, pageNumber, orderByField, orderByAscending } = {}, opts) {
    return await _.fetchJson(`/category-twelve${QS.query(QS.form({
        supplier,
        deleted,
        exclusiveStartKey,
        pageSize,
        pageNumber,
        orderByField,
        orderByAscending
    }))}`, {
        ...opts
    });
}
/**
 * Create a new Category Twelve record
 */
export async function postCategoryTwelve(upsertCategoryTwelveRequest, opts) {
    return await _.fetchJson("/category-twelve", _.json({
        ...opts,
        method: "POST",
        body: upsertCategoryTwelveRequest
    }));
}
/**
 * Get single Category Twelve record
 */
export async function getCategoryTwelveRecord(categoryTwelveUuid, opts) {
    return await _.fetchJson(`/category-twelve/${categoryTwelveUuid}`, {
        ...opts
    });
}
/**
 * Update an existing Category Twelve record
 */
export async function putCategoryTwelveRecord(categoryTwelveUuid, upsertCategoryTwelveRequest, opts) {
    return await _.fetchJson(`/category-twelve/${categoryTwelveUuid}`, _.json({
        ...opts,
        method: "PUT",
        body: upsertCategoryTwelveRequest
    }));
}
/**
 * Delete an existing Category Twelve record
 */
export async function deleteCategoryTwelveRecord(categoryTwelveUuid, opts) {
    return await _.fetchJson(`/category-twelve/${categoryTwelveUuid}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Retrieves the status of the data sync process
 */
export async function getDataSyncStatus(opts) {
    return await _.fetchJson("/data-sync-status", {
        ...opts
    });
}
/**
 * Get status of latest execution
 */
export async function getStepFunctionStatus(opts) {
    return await _.fetchJson("/getStepFunctionStatus", {
        ...opts
    });
}
/**
 * Get all Supplier records created by user
 */
export async function getSuppliersByUser({ supplierName, industry, supplierAddress, deleted, exclusiveStartKey, pageSize, pageNumber, orderByField, orderByAscending } = {}, opts) {
    return await _.fetchJson(`/supplier${QS.query(QS.form({
        supplierName,
        industry,
        supplierAddress,
        deleted,
        exclusiveStartKey,
        pageSize,
        pageNumber,
        orderByField,
        orderByAscending
    }))}`, {
        ...opts
    });
}
/**
 * Create a new Supplier
 */
export async function postSupplier(upsertSupplierRequest, opts) {
    return await _.fetchJson("/supplier", _.json({
        ...opts,
        method: "POST",
        body: upsertSupplierRequest
    }));
}
/**
 * Get single Supplier record
 */
export async function getSupplier(supplierUuid, opts) {
    return await _.fetchJson(`/supplier/${supplierUuid}`, {
        ...opts
    });
}
/**
 * Update existing supplier records
 */
export async function putSupplier(supplierUuid, upsertSupplierRequest, opts) {
    return await _.fetchJson(`/supplier/${supplierUuid}`, _.json({
        ...opts,
        method: "PUT",
        body: upsertSupplierRequest
    }));
}
/**
 * Delete existing supplier records
 */
export async function deleteSupplier(supplierUuid, opts) {
    return await _.fetchJson(`/supplier/${supplierUuid}`, {
        ...opts,
        method: "DELETE"
    });
}
export async function postSyncDataToYellowfin(opts) {
    return await _.fetchJson("/sync-data-to-yellowfin", {
        ...opts,
        method: "POST"
    });
}
export async function getCurrentUser(opts) {
    return await _.fetchJson("/user", {
        ...opts
    });
}
/**
 * Map available suppliers to their owners
 */
export async function putUserSupplierMapping(upsertUserSupplierMappingRequest, opts) {
    return await _.fetchJson("/user-supplier", _.json({
        ...opts,
        method: "PUT",
        body: upsertUserSupplierMappingRequest
    }));
}
