import { defineComponent } from 'vue';
import Suppliers from '@/views/Suppliers.vue';
export default defineComponent({
    name: 'SuppliersLayout',
    components: { Suppliers },
    data() {
        return {
            loading: false,
            tab: 'suppliers'
        };
    }
});
