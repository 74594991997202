export const envConfig = {
    env: process.env.NODE_ENV,
    apiBaseUrl: process.env.VUE_APP_BASE_URL
};
export const runtimeConfig = {};
export const activeConfig = () => {
    return {
        ...envConfig,
        ...runtimeConfig
    };
};
