import Notify from 'quasar/src/plugins/Notify.js';
Notify.setDefaults({
    position: 'top',
    timeout: 2500,
    textColor: 'white',
    actions: [{ icon: 'close', color: 'white' }]
});
const LogPlugin = {
    // export function LogPlugin (): void {
    // App.config.globalProperties.$log = {
    addMessage: (message) => {
        Notify.create({
            type: 'positive',
            message: message,
            attrs: {
                'data-cy': 'notify-success'
            }
        });
    },
    addCustomMessage: (options) => {
        Notify.create({
            type: options.type,
            message: options.message,
            position: options.position,
            timeout: options.timeout,
            textColor: options.textColor,
            actions: options.actions,
            html: options.html
        });
    },
    addWarning: (message) => {
        Notify.create({
            type: 'warning',
            timeout: 5000,
            message: message,
            attrs: {
                'data-cy': 'notify-warning'
            }
        });
    },
    addError: (error) => {
        console.log('error', error);
        if (error.message === 'AuthorizationException') {
            error.message = 'Authentication failed. Try logging in again.';
        }
        Notify.create({
            type: 'negative',
            timeout: 5000,
            message: error.message,
            attrs: {
                'data-cy': 'notify-error'
            }
        });
    }
};
// }
export default LogPlugin;
