import { defineComponent } from 'vue';
import { deleteSupplier, getSuppliersByUser } from '@/api/dataentry-api-generated';
import SupplierDialog from '@/components/SupplierDialog.vue';
import SupplierDuplicateDialog from '@/components/SupplierDuplicateDialog.vue';
import DeleteDialog from '@/components/DeleteDialog.vue';
export default defineComponent({
    name: 'Suppliers',
    components: { SupplierDialog, DeleteDialog, SupplierDuplicateDialog },
    data() {
        return {
            loading: false,
            items: [],
            filter: '',
            selected: [],
            columns: [
                {
                    name: 'action',
                    label: 'EDIT',
                    align: 'center'
                },
                {
                    name: 'duplicate',
                    label: 'Duplicate',
                    align: 'center'
                },
                {
                    field: 'supplierId',
                    name: 'supplierId',
                    label: 'Division ID',
                    sortable: true,
                    align: 'center'
                },
                {
                    field: 'supplierName',
                    name: 'supplierName',
                    label: 'Division',
                    sortable: true,
                    align: 'center'
                },
                {
                    field: 'industry',
                    name: 'industry',
                    label: 'Industry',
                    sortable: true,
                    align: 'center'
                },
                {
                    field: 'supplierAddress',
                    name: 'supplierAddress',
                    label: 'Head Office',
                    sortable: true,
                    align: 'center'
                },
                {
                    field: 'supplierState',
                    name: 'supplierState',
                    label: 'State',
                    sortable: true,
                    align: 'center'
                },
                {
                    field: 'supplierPostcode',
                    name: 'supplierPostcode',
                    label: 'Postcode',
                    sortable: true,
                    align: 'center'
                }
            ],
            tablePagination: {
                rowsPerPage: 50,
                rowsNumber: 0,
                sortBy: 'supplierId',
                descending: false,
                page: 1
            }
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        storeTablePagination() {
            return this.$store.getters.suppliersPagination;
        },
        deleteDialogTitle() {
            const length = this.selected.length;
            const item = length > 1 ? 'Suppliers' : 'Supplier';
            return `Delete ${length} ${item}`;
        },
        deleteDialogMsg() {
            const length = this.selected.length;
            const record = length > 1 ? 'these records' : 'this record';
            return `Are you sure you want to delete ${record}?`;
        }
    },
    watch: {
        storeTablePagination(newPagination) {
            this.tablePagination.rowsNumber = newPagination.rowsNumber;
            this.tablePagination.rowsPerPage = newPagination.rowsPerPage;
            this.tablePagination.page = newPagination.page;
        }
    },
    methods: {
        refresh() {
            this.fetch({
                pagination: {
                    page: 1,
                    rowsNumber: this.tablePagination.rowsNumber,
                    sortBy: this.tablePagination.sortBy,
                    descending: this.tablePagination.descending,
                    rowsPerPage: this.tablePagination.rowsPerPage
                }
            });
        },
        async getItems(filter, pageNumber, pageSize, orderByField, orderByAscending) {
            this.loading = true;
            try {
                const result = await getSuppliersByUser({ pageNumber: pageNumber, pageSize: pageSize, orderByField: orderByField, orderByAscending: orderByAscending, deleted: false });
                this.items = this.filterResults(result.items, filter);
                const newPagination = {
                    pageNumber: result.pageNumber,
                    totalNumberOfRecords: result.totalNumberOfRecords
                };
                this.setPagination(newPagination);
                this.loading = false;
            }
            catch (error) {
                this.$log.addError(error);
                this.loading = false;
            }
        },
        edit(uuid) {
            this.$router.push({ name: 'Supplier', params: { uuid: uuid } });
        },
        async deleteItems() {
            await Promise.all(this.selected.map(async (i) => {
                try {
                    await deleteSupplier(i.uuid);
                    this.$log.addMessage('Successfully deleted Supplier');
                }
                catch (error) {
                    this.$log.addError(error);
                }
            }));
            this.selected = [];
            this.refresh();
        },
        fetch(props) {
            let { page, rowsPerPage, sortBy, descending } = props.pagination;
            // update local pagination object
            this.tablePagination.page = page;
            this.tablePagination.rowsPerPage = rowsPerPage;
            this.tablePagination.sortBy = sortBy;
            this.tablePagination.descending = descending;
            // if sortBy is null, set to undefined - user has cleared the sort column
            sortBy = sortBy === null ? undefined : sortBy;
            this.getItems(this.filter, page, rowsPerPage, sortBy, !descending);
        },
        setPagination(newPagination) {
            this.tablePagination.page = newPagination.pageNumber;
            this.tablePagination.rowsNumber = newPagination.totalNumberOfRecords;
        },
        filterResults(results, filter) {
            if (filter !== undefined && filter.length > 0) {
                filter = filter ? filter.trim().toLowerCase() : filter;
                const filteredResults = results.filter((x) => {
                    let found = false;
                    // Search each individual 'value' in the object for the specified 'filter'
                    Object.keys(x).forEach(key => {
                        if (typeof x[key] === 'string') {
                            if (x[key].toLowerCase().includes(filter)) {
                                found = true;
                                // found something, stop searching
                            }
                        }
                        else if (typeof x[key] === 'number') {
                            if (x[key].toString().includes(filter)) {
                                found = true;
                                // found something
                            }
                        }
                    });
                    return found;
                });
                return filteredResults;
            }
            else {
                return results;
            }
        }
    }
});
