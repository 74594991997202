import { render } from "./SupplierComponent.vue?vue&type=template&id=4f0d10a0&scoped=true"
import script from "./SupplierComponent.vue?vue&type=script&lang=ts"
export * from "./SupplierComponent.vue?vue&type=script&lang=ts"

import "./SupplierComponent.vue?vue&type=style&index=0&id=4f0d10a0&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-4f0d10a0"

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QCard,QCardSection,QForm,QInput,QIcon,QTooltip,QSelect,QItem,QItemSection,QItemLabel});
